import _axios from 'axios'
import store from '@/store'
import { actions } from '@reducers/request'

const axios = _axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: function (status) {
    return (status >= 200 && status < 300) || status === 422
  },
})
let onUnauthorizedHandler

axios.interceptors.request.use(
  (config) => {
    store.dispatch(actions.launch())

    return config
  },
  (error) => {
    store.dispatch(actions.finish())

    return error
  }
)

axios.interceptors.response.use(
  (response) => {
    store.dispatch(actions.finish())

    return response
  },
  (error) => {
    if (error.response.status === 403) {
      onUnauthorizedHandler && onUnauthorizedHandler()
    }

    store.dispatch(actions.finish())

    return Promise.reject(error)
  }
)

// for DEBUG purposes only
axios.interceptors.response.use(
  (response) => {
    if (
      response.status === 200 &&
      String(response.data).startsWith('<script> Sfdump')
    ) {
      // Modal.info({
      //   title: 'DEBUG',
      //   width: 1024,
      //   maskClosable: true,
      //   content: <div dangerouslySetInnerHTML={{ __html: response.data }} />,
      // })

      return Promise.reject(new Error('Server Debug Dump'))
    } else {
      return response
    }
  },
  (error) => {
    if (error.response.status === 500) {
      // Modal.error({
      //   title: 'ERROR',
      //   width: 1024,
      //   maskClosable: true,
      //   content: error.response.data.message,
      // })

      return Promise.reject(new Error('Server Error 500'))
    }

    return Promise.reject(error)
  }
)

export function onUnauthorized(f) {
  onUnauthorizedHandler = f
}

export default axios
