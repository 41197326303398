import { createSlice } from '@reduxjs/toolkit'

export const requestSlice = createSlice({
  name: 'request',
  initialState: {
    runningCount: 0,
  },
  reducers: {
    launch: (s) => {
      s.runningCount += 1
    },
    finish: (s) => {
      s.runningCount -= 1
    },
  },
})

export const actions = requestSlice.actions

export const selectRunningCount = (s) => s.request.runningCount

export default requestSlice.reducer
