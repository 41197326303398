import axios from '@api/request'
import _ from 'lodash'
import { Mutex } from 'async-mutex'
import { useMemo, useCallback, useState, useRef } from 'react'
import { useRequest, useAntdTable, useMount, useUpdateEffect } from 'ahooks'
import { ListView, Modal, Toast } from 'antd-mobile'

const defaultTransformFactoryRequest =
  (request) =>
  (...params) => {
    let makeAxiosOptions
    if (typeof request === 'function') {
      makeAxiosOptions = request
    } else {
      makeAxiosOptions = () => request
    }

    // return axios.request(makeAxiosOptions(...params))
    return makeAxiosOptions(...params)
  }

const mergedToAntdRequest =
  (request) =>
  ({ current, pageSize }, filter) =>
    _.merge(
      {
        params: {
          page: current,
          page_size: pageSize,
          ...filter,
        },
      },
      request
    )

const GLOBAL_REQUEST_DEFAULT_OPTIONS = {
  manual: false,
  loadingDelay: 100,
  requestMethod: (params) => {
    return axios.request(params)
  },
  formatResult: (response) => response.data,

  // onError(error) {
  //   // Toast.fail('系统错误', 3)
  // },

  // custom options
  transformFactoryRequest: defaultTransformFactoryRequest, // transform factory request param into axios options
  useHook: useRequest, // hook used
  transformAxiosOptions: (o) => o, // transform axios options before sending
}

function makeFactory(typeDefaultOptions = {}) {
  // factory
  return function (factoryRequest, factoryDefaultOptions = {}) {
    // hook
    return (options = {}) => {
      const { hookOptions, useHook, request } = useMemo(() => {
        const o = _.defaults(
          options,
          factoryDefaultOptions,
          typeDefaultOptions,
          GLOBAL_REQUEST_DEFAULT_OPTIONS
        )

        const transformFactoryRequest = o.transformFactoryRequest
        const transformAxiosOptions = o.transformAxiosOptions
        delete o.transformFactoryRequest
        delete o.transformAxiosOptions

        const request = (...params) =>
          transformAxiosOptions(
            transformFactoryRequest(factoryRequest)(...params)
          )

        const useHook = o.useHook
        delete o.useHook

        return {
          hookOptions: o,
          useHook,
          request,
        }
      }, [options])

      // debugger
      return useHook(request, hookOptions)
    }
  }
}

// simple return the data, like 'show'
export const createDataRequest = makeFactory()

// for requests return collection, for routes like 'list'
export const createCollectionRequest = makeFactory({
  manual: false,
  initialData: [],
})

// for action requests, like 'delete'
export const createActionRequest = makeFactory({
  manual: true,
  formatResult(response) {
    const r = response.data

    if (r.success) {
      if (r.message) {
        Toast.success(r.message, 1.5)
      } else {
        Toast.success('操作成功', 1.5)
      }
    } else {
      if (r.message) {
        Toast.fail(r.message, 3)
      } else {
        Toast.fail('操作失败', 3)
      }
    }

    return Boolean(r.success)
  },
})

// for form requests, like 'update', 'create'
export const createFormRequest = makeFactory({
  manual: true,
  formatResult(response) {
    if (response.status === 422) {
      const content = (
        <div>
          {_(response.data.errors)
            .values()
            .flatten()
            .map((e, index) => <p key={index}>{e}</p>)
            .value()}
        </div>
      )
      Modal.alert('数据填写有误', content)
      return [false, null]
    } else if (response.data.success === true) {
      Toast.success(response.data.message || '提交成功', 1.5)
      return [true, response.data.extra]
    } else if (response.data.success === false) {
      Toast.fail(response.data.message || '提交失败', 3)
      return [false, null]
    } else {
      Toast.fail(response.data, 3)
      return [false, null]
    }
  },
})

export const createTableRequest = makeFactory({
  paginated: true,
  formatResult: (response) => ({
    list: response.data.data,
    total: response.data.meta.total,
  }),

  transformFactoryRequest: mergedToAntdRequest,
  useHook(...params) {
    const hook = useAntdTable(...params)

    hook.refreshAndGotoFirstPage = () => {
      const requestParams = hook.params
      requestParams[0].current = 1
      return hook.run(...requestParams)
    }

    return hook
  },
})

export const createScrollListRequest = makeFactory({
  manual: true,
  initialData: [],
  paginated: true,
  formatResult: (response) => ({
    list: response.data.data,
    total: response.data.meta.total,
  }),

  transformFactoryRequest: mergedToAntdRequest,
  useHook(...params) {
    const hook = useRequest(...params)
    const [dataSource, setDataSource] = useState(
      new ListView.DataSource({
        rowHasChanged: (row1, row2) => row1.id !== row2.id,
      })
    )
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState({})
    const data = useRef([])
    const page = useRef(0)
    const hasMore = useRef(true)
    const loadingMutex = useRef(new Mutex())

    useMount(() => hook.refresh())
    useUpdateEffect(() => {
      hook.refresh()
    }, [filter])

    hook.dataSource = dataSource
    hook.loading = loading
    hook.setFilter = setFilter

    const loadMoreData = useCallback(async () => {
      await loadingMutex.current.runExclusive(async () => {
        if (loading || !hasMore.current) {
          return
        }

        setLoading(true)

        page.current += 1

        let requestParams = hook.params
        if (requestParams.length === 0) {
          requestParams = [{ current: 0, pageSize: 20 }]
        }
        requestParams[0].current = page.current
        requestParams[1] = { ...(params[1].defaultFilter || {}), ...filter }
        const newData = await hook.run(...requestParams)

        if (newData.list.length === 0) {
          hasMore.current = false
        }

        data.current = data.current.concat(newData.list)
        setDataSource(dataSource.cloneWithRows(data.current))

        setLoading(false)
      })
    }, [hook, dataSource, data, loading, filter, params])

    hook.loadMoreData = loadMoreData
    hook.refresh = useCallback(() => {
      page.current = 0
      data.current = []
      setDataSource(dataSource.cloneWithRows(data.current))

      hasMore.current = true

      loadMoreData()
    }, [dataSource, loadMoreData])

    return hook
  },
})

export function url(path) {
  return axios.defaults.baseURL + '/' + path
}

// export async function downloadRequest(request) {
//   if (typeof request === 'string') {
//     window.location = request
//   } else {
//     const r = await request
//     if (r && r.status === 200) {
//       if (r.headers['content-disposition']) {
//         const c = contentDisposition.parse(r.headers['content-disposition'])
//         if (c.type === 'attachment') {
//           let blob = r.data
//           if (!(blob instanceof Blob)) {
//             blob = new Blob([blob], { type: r.headers['content-type'] });
//           }
//           saveAs(blob, c.parameters.filename)
//         }
//       }
//     }
//   }
// }
