import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
  },
  reducers: {
    set: (s, { payload: user }) => {
      s.user = user
    },
  },
})

export const actions = userSlice.actions

export const selectUser = (s) => s.user.user

export default userSlice.reducer
