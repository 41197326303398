import { configureStore } from '@reduxjs/toolkit'
import request from './reducers/request'
import user from './reducers/user'

export default configureStore({
  reducer: {
    request,
    user,
  },
})
