import { NavBar } from 'antd-mobile'
import { useMount } from 'ahooks'
import { BrowserRouter as Router } from 'react-router-dom'
import { onUnauthorized } from '@api/request'
import {
  DatabaseOutlined,
  DatabaseTwoTone,
  ProjectOutlined,
  ProjectTwoTone,
  IdcardOutlined,
  IdcardTwoTone,
  CheckCircleOutlined,
  CheckCircleTwoTone,
} from '@ant-design/icons'
import { Routes, useCurrentRouteUrl, BottomTabBar } from '@components/router'

const routes = [
  {
    title: '信息',
    url: 'information',
    icon: <DatabaseOutlined />,
    selectedIcon: <DatabaseTwoTone />,
    nav: true,
  },
  {
    title: '项目',
    url: 'project',
    icon: <ProjectOutlined />,
    selectedIcon: <ProjectTwoTone />,
    nav: true,
  },
  {
    title: '销售日报',
    url: 'project-job',
    icon: <IdcardOutlined />,
    selectedIcon: <IdcardTwoTone />,
    nav: true,
  },
  {
    title: '签到',
    url: 'checkin',
    icon: <CheckCircleOutlined />,
    selectedIcon: <CheckCircleTwoTone />,
    nav: true,
  },
  {
    url: '',
    redirect: 'information',
  },
]

function TopNavBar() {
  const url = useCurrentRouteUrl()

  let title = ''
  const route = routes.find((e) => e.url === url)
  if (route) {
    title = route.title
  }

  return (
    <div style={{ position: 'fixed', width: '100%', top: 0 }}>
      <NavBar mode="light">{title}</NavBar>
    </div>
  )
}

export default function Main() {
  useMount(() => {
    onUnauthorized(() => {
      window.location.href =
        '/backend/cas/login?from=' + encodeURIComponent(window.location.href)
    })
  })

  return (
    <Router>
      <TopNavBar style={{ position: 'fixed', width: '100%', bottom: 0 }} />
      <BottomTabBar routes={routes} />
      <div style={{ paddingTop: 45, paddingBottom: 50 }}>
        <Routes routes={routes} />
      </div>
    </Router>
  )
}
