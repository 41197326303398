import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { TabBar, Tabs } from 'antd-mobile'
import loadable from '@loadable/component'

export function useCurrentRoutePrefix(type) {
  const route = useRouteMatch()
  return route[type] === '/' ? '' : route[type]
}

export function useCurrentRouteUrl() {
  const { path: prefix } = useRouteMatch()
  const location = useLocation()

  return location.pathname
    .substr(prefix.length === 1 ? 0 : prefix.length)
    .split('/')[1]
}

export function Routes({ routes }) {
  const prefix = useCurrentRoutePrefix('path')

  return (
    <Switch>
      {routes.map((e, index) => {
        if (e.redirect !== undefined) {
          return <Redirect exact key={index} to={`${prefix}/${e.redirect}`} />
        } else {
          const component = e.component || e.url
          const Component = loadable(() =>
            import(`../routes${prefix}/${component}`)
          )
          return (
            <Route key={index} path={`${prefix}/${e.url}`}>
              <Component />
            </Route>
          )
        }
      })}
    </Switch>
  )
}

export function BottomTabBar({ routes }) {
  const history = useHistory()
  const prefix = useCurrentRoutePrefix('path')
  const url = useCurrentRouteUrl()

  const items = routes
    .filter((e) => e.nav)
    .map((e, index) => (
      <TabBar.Item
        title={e.title}
        key={index}
        icon={e.icon}
        selectedIcon={e.selectedIcon}
        selected={e.url === url}
        onPress={() => history.push(`${prefix}/${e.url}`)}
      />
    ))

  return (
    <div style={{ position: 'fixed', width: '100%', bottom: 0 }}>
      <TabBar noRenderContent={true}>{items}</TabBar>
      <div style={{ width: '100%', height: 15, backgroundColor: 'white' }} />
    </div>
  )
}

export function TopTabs({ routes }) {
  const history = useHistory()
  const prefix = useCurrentRoutePrefix('path')
  const url = useCurrentRouteUrl()

  let page = null

  const tabs = routes
    .filter((e) => e.nav)
    .map((e, index) => {
      if (e.url === url) {
        page = index
      }
      return {
        title: e.title,
        key: e.url,
      }
    })

  return (
    <div style={{ position: 'fixed', width: '100%', top: 45 }}>
      <Tabs
        tabs={tabs}
        page={page}
        onChange={(tab) => history.push(`${prefix}/${tab.key}`)}
      />
    </div>
  )
}
