import { useMount } from 'ahooks'
import { Provider } from 'react-redux'
import store from './store'
import { useMeRequest } from '@api/auth'
import { actions } from '@reducers/user'

import Main from '@routes/Main'

export default function App() {
  const meRequest = useMeRequest({ manual: true })

  useMount(async () => {
    const me = await meRequest.run()
    store.dispatch(actions.set(me))
  })

  return (
    <Provider store={store}>
      <Main />
    </Provider>
  )
}
